import styled from "styled-components";

export const Container = styled("div")`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;


export const Form = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 400px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;

  h1 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
  }
`;

export const InputContainer = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  background: #f9f9f9;
  border: 1px solid ${({ theme }) => theme.colors['gray-700']};
  border-radius: 8px;
  padding: 10px;

  label {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors['gray-600']};
    font-size: 1.2rem;
  }

  input {
    border: none;
    background: #f9f9f9;
    flex: 1;
    padding: 10px;
    font-size: 1rem;


    &:focus {
      outline: none;
    }
  }
`;

export const StyledButton = styled("button")`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #28a745; /* Cor verde */
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #218838; /* Cor verde mais escura */
  }
`;

export const ErrorMessage = styled("span")`
  color: #dc3545;
  font-size: 14px;
`;

export const StyledLink = styled("a")`
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
