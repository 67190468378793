import { FormEvent, useState } from "react";
import { FaUser, FaLock } from "react-icons/fa"; // Importando ícones
import Input from "../../components/Input/Input";
import {
  InputContainer,
  Container,
  Form,
  ErrorMessage,
  StyledLink,
} from "../StylesAuth/styles";
import { CustomButton } from '../../components/CustomButton';
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

/**
 * Register page
 */
function Register() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([""]);

  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleUserChange = (event: FormEvent<HTMLInputElement>) => {
    setUser(event.currentTarget.value);
  };

  const handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleConfirmPasswordChange = (event: FormEvent<HTMLInputElement>) => {
    setConfirmPassword(event.currentTarget.value);
  };

  const handleSubmit = async (event?: FormEvent) => {
    if (event) event.preventDefault();
    console.log(user, password);

    if (!user || !password) {
      setErrors(["Fill all fields"]);
      return;
    }

    if (user && password) {
      try {
        const status = await signIn(
          {
            user,
            password,
          },
          true
        );
        if (status !== 200) throw new Error("Invalid credentials");
        navigate("/");
      } catch (error) {
        console.error("Error when trying to login", error);
        setErrors(["Credenciais inválidas"]);
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h1>User Register</h1>
        <InputContainer>
          <label htmlFor="username">
            <FaUser />
          </label>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleUserChange}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="password">
            <FaLock />
          </label>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            onChange={handlePasswordChange}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="password">
            <FaLock />
          </label>
          <Input
            type="password"
            name="password-confirm"
            placeholder="Confirm Password"
            onChange={handleConfirmPasswordChange}
          />
          {password !== confirmPassword && confirmPassword.length > 0 && (
            <ErrorMessage>Passwords don't match</ErrorMessage>
          )}
        </InputContainer>
        {errors && <ErrorMessage>{errors}</ErrorMessage>}

        <CustomButton
          label="Register"
          onClick={() => handleSubmit()}
        />
        <StyledLink href="/login">Already have an account? Login</StyledLink>
      </Form>
    </Container>
  );
}

export default Register;
