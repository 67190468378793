import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

/**
 * Props for the PrivateRoute component.
 * @property children - The components or elements to render within the route.
 * @property type - Defines the access requirement for the route:
 *   - "authorized": Accessible only to authenticated users.
 *   - "unauthorized": Accessible only to non-authenticated users.
 */
interface PrivateRouteProps {
  children: ReactNode;
  type: "authorized" | "unauthorized";
}

/**
 * PrivateRoute component to manage route accessibility based on authentication state.
 * @param children - Components or elements to render.
 * @param type - The access control type.
 * @returns JSX.Element or navigation redirection.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, type }) => {
  const { isAuthenticated } = useAuth();

  const isAuthorizedAccess = type === "authorized" && isAuthenticated;
  const isUnauthorizedAccess = type === "unauthorized" && !isAuthenticated;

  if (!isAuthorizedAccess && !isUnauthorizedAccess) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
