import { FormEvent, useState } from "react";
import { FaUser, FaLock } from "react-icons/fa"; // Importando ícones
import Input from "../../components/Input/Input";
import {
  InputContainer,
  Container,
  Form,
  ErrorMessage,
  StyledLink,
} from "../StylesAuth/styles";
import { CustomButton } from '../../components/CustomButton';
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

/**
 * Login page.
 */
function Login() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([""]);
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleUserChange = (event: FormEvent<HTMLInputElement>) => {
    setUser(event.currentTarget.value);
  };

  const handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleSubmit = async (event?: FormEvent) => {
    if (event) event.preventDefault();
    setLoading(true);

    if (!user || !password) {
      setErrors(["Fill all fields"]);
      setLoading(false);
      return;
    }

    try {
      const status = await signIn({
        user,
        password,
      });
      if (status !== 200) throw new Error("Invalid credentials");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error when trying to login", error);
      setErrors(["Invalid credentials"]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h1>User Login</h1>
        <InputContainer>
          <label htmlFor="username">
            <FaUser />
          </label>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleUserChange}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="password">
            <FaLock />
          </label>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            onChange={handlePasswordChange}
          />
        </InputContainer>
        {errors && <ErrorMessage>{errors}</ErrorMessage>}

        <CustomButton
          label="Login"
          onClick={() => handleSubmit()}
          disabled={loading}
        />
        <StyledLink href="/register">
          Don't have an account? Register here
        </StyledLink>
      </Form>
    </Container>
  );
}

export default Login;
