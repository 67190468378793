import { createContext, ReactNode, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

/**
 * SignInCredentials interface.
 */

type SignInCredentials = {
  user: string;
  password: string;
};

/**
 * User interface.
 */
type User = {
  name: string;
};

/**
 * AuthContextData interface.
 */
type AuthContextData = {
  signIn: (
    credentials: SignInCredentials,
    newUser?: boolean
  ) => Promise<number>;
  signOut: () => void;
  isAuthenticated: boolean;
  user: User | null;
  token: string;
};

/**
 * AuthProvider props interface.
 */
interface AuthProviderProps {
  children: ReactNode;
}

/**
 * Context that allows the rest of application verify and control the user's authenticate and authorization.
 */
export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const tokenPath = "auth-token";
  const [cookies, setCookie, removeCookie] = useCookies([tokenPath]);
  const token = cookies[tokenPath];
  const isAuthenticated = !!user;

  const api = axios.create({
    baseURL: process.env.REACT_APP_AUTHENTICATION!,
  });
  /**
   *  If the user is logged, set the user information and the token in header,
   * otherwise the user is null and remove the token from the header.
   */
  const setUserIsLogged = (token: string) => {
    if (token) {
      const newUser = jwtDecode<{
        name: string;
      }>(token);
      setUser({ ...newUser });
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      setUser(null);
      delete api.defaults.headers.common.Authorization;
    }
  };

  /**
   * Check the auth token presence.
   */
  useEffect(() => {
    setUserIsLogged(token);
  }, [token]);

  /**
   * Sign out the user, removing the auth token and redirecting to home.
   */
  const signOut = () => {
    removeCookie(tokenPath);
  };

  /**
   * Sign in the user using the user and password credentials.
   */
  const signIn = async (
    { user, password }: SignInCredentials,
    register: boolean = false
  ) => {
    try {
      const path = register ? "auth/register" : "auth/login";
      const response = await api.post(path, {
        user,
        password,
      });

      const { token: authToken } = response.data;

      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 1);

      setCookie(tokenPath, authToken, {
        expires: expiration,
      });
      setUserIsLogged(authToken);
      return 200;
    } catch (error) {
      console.error(error);
      return 400;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isAuthenticated,
        user,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
