import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import { Publications } from '../Pages/Publications';
import { Team } from '../Pages/Team';
import { Home } from '../Pages/Home';
import { Layout } from '../Pages/Layout';
import { PageNews } from '../Pages/News';
import { ViewPost } from '../Pages/ViewPost';
import { PageTools } from '../Pages/Tools';

import { ToolClassifier } from '../components/ToolClassifier';
import { ToolDetector } from '../components/ToolDetector';

import Login from "../Pages/Login";
import Register from "../Pages/Register";
import PrivateRoute from "./PrivateRoute";

type RouteType = {
  path: string;
  element: JSX.Element;
};

type RoutesType = {
  publicRoutes: RouteType[];
  authRoutes: RouteType[];
  notAuthRoutes: RouteType[];
};

const routes: RoutesType = {
  publicRoutes: [
    {
      path: "tools",
      element: <PageTools />,
    },
    {
      path: "tools/classifier",
      element: <ToolClassifier />,
    },
    {
      path: "tools/detector",
      element: <ToolDetector />,
    },
    {
      path: "news",
      element: <PageNews />,
    },
    {
      path: "news/post",
      element: <ViewPost />,
    },
    {
      path: "publications",
      element: <Publications />,
    },
    {
      path: "team",
      element: <Team />,
    }
  ],
  authRoutes: [],
  notAuthRoutes: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
  ],
};

export const Routers: React.FC = () => (
  <RoutesDom>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      {routes.publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {routes.authRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute type="authorized">{route.element}</PrivateRoute>
            }
          />
        ))}
        {routes.notAuthRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute type="unauthorized">{route.element}</PrivateRoute>
            }
          />
        ))}
    </Route>
  </RoutesDom>
);
