import React, { useState } from 'react';
import { PageHeader } from '../PageHeader';
import { Container, Content } from './styles';
import { ImageSelector } from '../ImageSelector';
import { CustomButton } from '../CustomButton';
import axios from 'axios';
import { CustomTable } from '../CustomTable';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaInfoCircle } from 'react-icons/fa';

interface ToolDetectorProps {}

interface ReturnApiDTO {
  filename: string;
  title: string;
  isLesion: boolean;
  result: number;
}

export const ToolDetector: React.FC<ToolDetectorProps> = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [predictions, setPredictions] = useState<ReturnApiDTO[][]>([]);
  const [loading, setLoading] = useState(false);
  const [legalNotice, setLegalNotice] = useState(false);
  const [zipFile, setZipFile] = useState<string | null>(null);
  const apiRouter = process.env.REACT_APP_DETECT!;

  const handleUpload = async () => {
    setPredictions([]);
    setZipFile(null);
  
    if (selectedFiles.length > 0) {
      setLoading(true);
      const formData = new FormData();
  
      selectedFiles.forEach((file) => {
        formData.append('file', file);
      });
  
      try {
        const response = await axios.post(apiRouter, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 202) {
          alert(
            'Your request has been accepted and is in the queue for processing. Please check back in a few moments for the results.'
          );
          return;
        }
        
        console.log(response.data)
        if (response.status === 200) {
          const data = response.data;
  
          // Extract ZIP file data (if any)
          if (data?.zip_file) {
            setZipFile(data.zip_file); // Save file link or content
          }
  
          // Extract additional response data
          if (data?.predictions?.result) {
            setPredictions(data.predictions.result);
          }
        } else {
          alert(`Unexpected response status: ${response.status}`);
        }
      }  catch (error: any) {
        if (error.response && error.response.status === 429) {
          alert('You have exceeded the allowed number of requests. Please try again later.');
        } else {
          console.error('Error during upload:', error);
          alert(
            'Something is wrong. Try again later!\nIf this problem persists, please contact us!'
          );
        }
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please select a file to upload.');
    }
  };

  const downloadFile = () => {
    if (!zipFile) {
      alert("No file available for download.");
      return;
    }
  
    try {
      // Convert the hex string back to binary data
      const binaryData = new Uint8Array(
        zipFile.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
      );
  
      // Create a Blob from the binary data
      const blob = new Blob([binaryData], { type: 'application/zip' });
  
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'glomerulus.zip'; // Specify the file name
      link.click();
  
      // Revoke the Blob URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error while downloading the file:", error);
      alert("Failed to download the file.");
    }
  }; 

  const clearPredictions = () => {
    setPredictions([]);
    setZipFile(null);
  };

  const generateTableHeaders = (predictions: ReturnApiDTO[][]) => {
    const filenames = predictions.map(
      (predictionArray) => predictionArray[0]?.filename || 'Unknown Image'
    );
    return ['Lesion', ...filenames];
  };

  const generateTableData = (predictions: ReturnApiDTO[][]) => {
    const lesionTitles = predictions[0]
      .filter((item) => item.title !== 'Normal')
      .map((item) => item.title);

    return lesionTitles.map((lesionTitle, rowIndex) => {
      const row = [
        <td key={`lesion-${rowIndex}`}>
          {lesionTitle === 'ECDC' ? (
            <span className="tooltip">
              ECDC
              <FaInfoCircle
                data-tip="Epithelial Cell Degenerative Changes"
                style={{ marginLeft: '10px', color: 'blue' }}
              />
              <span className="tooltiptext">Epithelial Cell Degenerative Changes</span>
            </span>
          ) : (
            lesionTitle
          )}
        </td>,
      ];

      for (let i = 0; i < predictions.length; i++) {
        const predictionArray = predictions[i];
        const lesionPrediction = predictionArray.find(
          (item) => item.title === lesionTitle
        );
        const isLesion = lesionPrediction ? lesionPrediction.isLesion : false;

        row.push(
          <td
            key={`prediction-${rowIndex}-${i}`}
            style={{ color: isLesion ? 'red' : 'gray' }}
          >
            {isLesion ? 'YES' : 'NO'}
          </td>
        );
      }

      return <tr key={`row-${rowIndex}`}>{row}</tr>;
    });
  };

  return (
    <Container>
      <Content>
        <PageHeader
          title="PATHOSPOTTER Detector"
          subTitle="Detection of glomeruli in renal biopsy images."
        />
        <label>
          Select whole slide images or individual sections in TIFF format, with a maximum size of up to 1 GB.
        </label>
        <div className="main-content">
          <div
            className={'image-section-total'}
          >
            <ImageSelector
              label=""
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles}
              clearPredictions={clearPredictions}
              maxImages={1}
              maxSizeImage={1000}
              imagesType={['.tiff']}
            />
            {selectedFiles.length > 0 && (
              <CustomButton
                label="Send Images"
                onClick={handleUpload}
                disabled={loading}
              />
            )}
          </div>
        </div>
        {selectedFiles.length > 0 && zipFile && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomButton
              label="Download glomerulus"
              onClick={downloadFile}
              disabled={loading}
            />
          </div>
        )}
        {predictions.length > 0 && (
          <div className="main-content">
            <div className="table-section-total">
              <CustomTable
                data={generateTableData(predictions)}
                headers={generateTableHeaders(predictions)}
              />
            </div>
          </div>
        )}
        <div className="actions">
          <button
            onClick={() => {
              setLegalNotice(!legalNotice);
            }}
            type="button"
            className="button-abstract"
          >
            {legalNotice ? <FiChevronUp /> : <FiChevronDown />}
            <span>Legal Notice</span>
          </button>
        </div>
        {legalNotice && (
          <div className="box-abstract">
            <p className="text-secondary">
              The information contained on this site, or provided by its
              request, is provided for informational or academical purposes only
              and is not intended to be a medical or health advice. We do not
              send or sell this information for the purpose of diagnosis,
              treatment, cure or prevention of any disease. We do not recommend
              any particular form of medical treatment or that people administer
              their own health problems without the advice of a licensed health
              professional. Information found or received through this site
              should not be used in lieu of a visit, call, consultation or
              advice from a health care provider. If you suspect you have a
              medical problem, or if you have any questions about your health
              care, call or see your doctor.
            </p>
            <p className="text-secondary">
              This site, or any part thereof, may not be reproduced, duplicated,
              copied, sold, resold, visited or otherwise exploited for any
              commercial purpose without the express written consent of the
              persons responsible for the PathoSpotter Project. You may not use
              any "meta tag" or any other "hidden text" using the PathoSpotter
              name or trademark without the express written consent of the
              project's owners. Any unauthorized use will be subject to
              investigation and application of legal penalties.
            </p>
            <p className="text-secondary">
              As a condition of your use of this site, you warrant that you will
              not use it for any purpose that is unlawful or prohibited by this
              notice. You may not use this site in any manner that damages,
              disables, overburden, or harms you. You may not obtain, or attempt
              to obtain, through this site, materials or information by any
              means that has not been intentionally made available or provided
              through it.
            </p>
            <p className="text-secondary">
              By submitting an image for review, you agree that the PathoSpotter
              Project may use it, free of any liens or legal obligation to you,
              the author of the image, or the owner of the image direct use of
              the image; in order to develop, test and improve their algorithms,
              including conducting surveys, and testing and solving problems
              related to the operation of systems and products related to the
              PathoSpotter Project. If you do not own the image rights you have
              submitted, you may be legally responsible for using this image
              without the permission of the owners of image's rights and subject
              to appropriate legal penalties.
            </p>
            <p className="text-secondary">
              This site collects information from and about computers,
              smartphones, and other devices used to connect to this page, and
              uses this information for security control and to monitor and
              enhance products and systems related to the PathoSpotter Project.
            </p>
          </div>
        )}
      </Content>
    </Container>
  );
};
