import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, UploadArea, PreviewContainer, FileContainer, ErrorContainer, Modal, Overlay, ModalButtonContainer } from './styles';
import { FaSearchPlus, FaSearchMinus, FaTrashAlt, FaUndo, FaTimes, FaFileImage } from 'react-icons/fa';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface ImageSelectorProps {
  label: string;
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  clearPredictions: () => void;
  children?: any;
  showPreview?: boolean;
  maxImages?: number;
  maxSizeImage?: number;
  imagesType?: string[];
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({
  label,
  selectedFiles,
  setSelectedFiles,
  clearPredictions,
  children,
  showPreview = true,
  maxImages = 5,
  maxSizeImage = 10,
  imagesType = ['.png', '.jpg', '.jpeg']
}) => {
  const [fileErrors, setFileErrors] = useState<{ file: File; error: string }[]>([]);
  const [modalFile, setModalFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
    const newFiles = [...selectedFiles, ...acceptedFiles];
    if (newFiles.length > maxImages) {
      alert(`Maximum number of images exceeded. Only ${maxImages} images allowed.`);
      const allowedFiles = newFiles.slice(0, maxImages);
      setSelectedFiles(allowedFiles);
      setFileErrors([...fileErrors, ...fileRejections.map((rejection) => ({
        file: rejection.file,
        error: 'Maximum number of images exceeded.',
      }))]);
    } else {
      setSelectedFiles(newFiles);
      setFileErrors([...fileErrors, ...fileRejections.map((rejection) => ({
        file: rejection.file,
        error: rejection.errors[0].message,
      }))]);
    }
  }, [selectedFiles, setSelectedFiles, fileErrors]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': imagesType,
    },
    maxSize: maxSizeImage * 1024 * 1024,
  });

  const removeFile = (file: File) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    clearPredictions(); // Limpe as previsões ao remover o arquivo
  };

  const removeErrorFile = (file: File) => {
    setFileErrors((prevErrors) => prevErrors.filter((error) => error.file !== file));
  };

  const openModal = (file: File) => {
    setModalFile(file);
  };

  const closeModal = () => {
    setModalFile(null);
  };

  return (
    <Container>
      <p>{label}</p>
      <PreviewContainer>
        {selectedFiles.map((file, index) => {
          const isTiff = file.name.toLowerCase().endsWith('.tiff');
          return (
            <FileContainer key={index}>
              <div className="file-name">{file.name}</div>
              {isTiff ? (
                <FaFileImage size={50} style={{ color: 'gray', margin: '20px auto' }} />
              ) : (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  style={{ 
                    maxWidth: '320px', 
                    maxHeight: '225px', 
                    display: 'block', 
                    cursor: 'zoom-in' 
                  }}
                  onClick={() => openModal(file)} // Abrir o modal ao clicar na imagem
                />
              )}
              <div className="button-container">
                {!isTiff && (
                  <button onClick={() => openModal(file)}>
                    <FaSearchPlus />
                  </button>
                )}
                <button onClick={() => removeFile(file)}><FaTrashAlt /></button>
              </div>
            </FileContainer>
          );
        })}
        {fileErrors.map((errorItem, index) => (
          <ErrorContainer key={index}>
            <span>{errorItem.file.name}</span>
            <span className="error-message">{errorItem.error}</span>
            <button onClick={() => removeErrorFile(errorItem.file)}>
              <FaTrashAlt />
            </button>
          </ErrorContainer>
        ))}
        {selectedFiles.length < maxImages && (
          <UploadArea {...getRootProps()}> 
            <input {...getInputProps()} />
            <p>Choose files or drag & drop here</p>
          </UploadArea>
        )}
      </PreviewContainer>

      {modalFile && (
        <Overlay onClick={closeModal}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <TransformComponent>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '100%', maxHeight: 'calc(100vh - 40px)', overflow: 'hidden' }}>
                      <img
                        src={URL.createObjectURL(modalFile)}
                        alt="Modal Preview"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  </TransformComponent>
                  <ModalButtonContainer>
                    <button onClick={() => zoomIn()}><FaSearchPlus /></button>
                    <button onClick={() => zoomOut()}><FaSearchMinus /></button>
                    <button onClick={() => resetTransform()}><FaUndo /></button>
                    <button 
                      onClick={() => closeModal()}
                      style={{ color: 'red', display: 'flex', top: '0', right: '0', position: 'absolute' }}>
                        <FaTimes />
                    </button>
                  </ModalButtonContainer>
                </>
              )}
            </TransformWrapper>
          </Modal>
        </Overlay>
      )}
    </Container>
  );
};
