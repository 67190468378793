import { InputHTMLAttributes } from "react";
import { StyledInput } from "./Input.styles";

/**
 *  Input component.
 */
function Input({ ...props }: InputHTMLAttributes<HTMLInputElement>) {
  return <StyledInput {...props} />;
}
export default Input;
