import styled from "styled-components";

export const StyledInput = styled("input")`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  margin: 5px 0;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;
